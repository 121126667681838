@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.logo {
  width: 60%;
  margin: 2rem auto;
  display: block;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.order-preview {
  border: 1px solid #dee5ea;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAHElEQVQYV2P89OmTLwMOwAiS5OPj24xNftBJAgCtxRsr1/rdqQAAAABJRU5ErkJggg==);
}

.order-preview-img {
  background-color: rgba(0, 0, 0, 0.1);
}

.order-preview img {
  width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.preview {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.preview__shirt {
  display: block;
  width: 100%;
}

.preview__graphic {
  display: block;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 36%;
}
